import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import SocialHeaderImage from '../../../common/src/assets/images/portfolio/social-header.jpeg';

function SEO({ description, lang, meta, keywords, title }) {
  return (
    //TODO: can clean this up
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription.substring(0, 150),
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: data.site.siteMetadata.siteUrl,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:locale`,
                content: `en_US`,
              },
              {
                property: `og:image`,
                content: SocialHeaderImage,
              },
              {
                name: `twitter:card`,
                content: metaDescription.substring(0, 120),
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [
    'google flutter',
    'flutter',
    'flutterdev',
    'flutter developer',
    'top flutter app development company',
    'google dart',
    'flutter developer for hire',
    'iot flutter developer',
    'flutter agency',
    'hire flutter developer',
  ],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`;
